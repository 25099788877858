import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../../assets/images/market_ui/slack/img_4.png");
const VidImg = require("../../../assets/img/integration/bamboohr/video_img_bamboo.png");

const Section1 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_1.png");
const Section2 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_2.jpeg");
const Section3 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_3.gif");
const Section4 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_4.jpeg");
const Section5 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_5.gif");
const Section6 = require("../../../assets/img/blogs/digi_assit_blog/dig_assit_6.jpeg");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Learn how digital assistants can enable business continuity during covid-19"
        description="Learn how companies are achieving business continuity during pandemic using conversational AI-powered self-service options for employees (chatbots) that provide real-time solution instantly."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={Section1}
        schema={ldSchema}
    
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            Get over the Pandemic standstill: Leapfrog with
                            Digital Assistants that enable business continuity
                            during COVID-19
                          </h1>
                        </div>
                      </div>
                      <img src={Section1} />
                      <div className="market_wrapper_page">
                        <p class="font-section-normal-text mt-4 line-height-2">
                          Ah, yes. “I’m working from home today.” Just thinking
                          about saying this (before all the COVID drama started)
                          makes one genuinely happy. While everyone’s at the
                          office, you get to enjoy a day or two taking
                          work-from-home — relaxing a bit, sipping a cup of
                          coffee, as you work at your own pace, without anyone
                          looking over your shoulder, listening to some music on
                          your TV or smart speaker. But now, what you used to
                          enjoy occasionally, has become your daily routine.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Yes, I’m talking about how COVID-19 has involuntarily
                          pushed each and every one of us into working from
                          home. Or remote working as everyone so calls it as if
                          it was discovered recently.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Remote working has become the new norm now owing to
                          the coronavirus outbreak to promote social distancing
                          and prevent hospitals worldwide from getting
                          overwhelmed with a huge number of patients they can’t
                          handle. Businesses, especially now, are looking toward
                          efficient remote working tools and processes to enable
                          Business As Usual (BAU).
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Enabling BAU is not that hard, in my opinion.
                          BAU/Business Continuity can be easily achieved if one
                          knows where to look. I’m talking about{" "}
                          <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                            AI-powered self-service
                          </a>{" "}
                          options for employees (chatbots) that provide
                          real-time solutions through automated workflows.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Because confidence in automation and AI as a means to
                          minimize the impact of the pandemic is growing,
                          according to 16% of 181 European companies{" "}
                          <a href="https://www.idc.com/getdoc.jsp?containerId=US46145120">
                            surveyed
                          </a>{" "}
                          by IDC in late March. The firm also noted that the
                          application of AI to automate processes is a crucial
                          response to the crisis, but it could also lead to
                          increased adoption for other long-term goals.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          “As a short-term response to the COVID-19 crisis, AI
                          can play a crucial part in automating processes and
                          limiting human involvement to a necessary minimum,”
                          said Petr Vojtsek, research analyst at IDC, in a
                          statement. “In the longer term, we might observe an
                          increase in AI adoption for companies that otherwise
                          wouldn’t consider it, both for competitive and
                          practical reasons.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          But more on that later. Because, before talking about
                          the solutions, let’s see what challenges you might
                          face as a business trying to embrace remote working
                          out of the blue.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          Challenges
                        </h2>
                        <h6 className="font-section-sub-header-small-bold">
                          1. No one was prepared for it, why should you be?
                        </h6>
                        <img loading="lazy" src={Section2} />

                        <p class="font-section-normal-text mt-4 line-height-2">
                          You’re not alone. Businesses all over the world are
                          still in shock. Some have even shut shop (I feel for
                          those poor buggers). But that’s not to say it’s okay
                          to remain in a state of standstill. Life has to go on.
                          The sooner your business embraces the fact that
                          work-from-home is going to be there for a lonnng time,
                          the sooner you can come up with short-term and
                          long-term solutions.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          2. Get used to people getting sick often
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Yeah, you heard that right. This one’s not on your
                          hands. No pun intended 😉 Given the fact that COVID-19
                          remains on aerosols in the air for about{" "}
                          <a href="https://www.scientificamerican.com/article/how-coronavirus-spreads-through-the-air-what-we-know-so-far1/">
                            three hours&nbsp;
                          </a>
                          when a person coughs without covering their mouth with
                          a surgical mask or their elbow, community spreading
                          becomes inevitable.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          And community spreading means, employees will get
                          sick. Nope, you can’t avoid it. There will be both
                          asymptomatic employees and employees who are
                          symptomatic and you can’t do anything about it. The
                          least you can do is try to be understanding of those
                          poor chaps who have locked themselves without any kind
                          of human interaction.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          3. Ramping up security is the need of the hour
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Businesses already had to deal with Bring Your Own
                          Devices (BYOD) to the workplace that presented a
                          &nbsp;
                          <a href="https://vittana.org/10-biggest-byod-pros-and-cons">
                            labyrinthine of problems
                          </a>{" "}
                          in itself. Now, with employees working from home on
                          their laptops and mobile devices, fortifying your VPN
                          to handle huge traffic and ramping up protection
                          against threats like viruses and ransomware becomes
                          imminent.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          4. IT and HR departments will take a beating
                        </h6>
                        <iframe
                          src="https://giphy.com/embed/koPSBhdvbCD4c"
                          width="480"
                          height="254"
                          frameBorder="0"
                          class="giphy-embed display-none-gif"
                          allowFullScreen
                        ></iframe>
                        <p></p>
                        <p class="font-section-normal-text line-height-2">
                          You know who will be the most affected among your
                          employees? Your service desk agents and your human
                          resources personnel. Here’s why.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          At the workplace, before all this work-from-home
                          frenzy, IT service desk agents usually will be
                          burdened with a huge volume of IT support tickets
                          rising from employees not being able to log in to
                          their desktop/laptop owing to forgetting their
                          password or their{" "}
                          <a href=" https://workativ.com/conversational-ai-platform/5-benefits-of-having-automation-for-unlock-account-requests">
                            account being locked.
                          </a>{" "}
                          Now that employees are working from home and with some
                          of the service desk agents falling sick, it’ll be a
                          gigantic task for your internal IT team to tackle all
                          the queries swarming around them.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Adding to your service desk agents’ problems, it’ll
                          take hours for them to actually determine the cause of
                          the problem, whether there’s a problem on the business
                          servers or whether the employees’ device is faulty,
                          now that employees are using their own devices. A
                          difficult situation indeed.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now let’s talk about HR. There will definitely be a
                          slew of questions from employees for HRs to root
                          through regarding work-from-home policies and the next
                          steps. Simultaneously, HRs will have to deal with
                          onboarding/offboarding employees as well as training
                          new joiners. A precarious position to be in.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          5. Work/Life balance, what?
                        </h6>
                        <img loading="lazy" src={Section4} />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          Commuting to the office on a car/bike/bus/train,
                          enjoying the gentle breeze on your face, observing
                          people from different walks of life around you, and
                          starting work after reaching office. Then, when the
                          office gets over, leaving work-related stuff at the
                          office, enjoying the wind and paying attention to
                          nature and the people you come across on your way
                          home, reaching home, having dinner, and spending some
                          quality time with your loved ones. Just reading this
                          alone makes you feel at peace, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This used to be the routine of many of us before
                          COVID-19. Now, after work-from-home has come into
                          effect, due to the government imposing strict rules
                          around social distancing, the line between work and
                          home has begun to blur for the many of us, with some
                          of us working round the clock just because we are at
                          home.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There might be more work-from-home challenges that you
                          might face during this pandemic, but, according to me,
                          these are the most important ones that’ll you have to
                          pay attention to if you want to work out business
                          continuity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now let’s take a look at some of the possible
                          solutions to streamline business continuity.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h6 className="font-section-sub-header-small-bold">
                          Solutions
                        </h6>
                        <h6 className="font-section-sub-header-small-bold">
                          1. Come up with proper work-from-home policies and
                          communicate them
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          C-suite personnel like CEO, CTO, CIO, and managers of
                          an organization should come together and work on
                          establishing ground rules that employees have to
                          follow while they are working from home, for their own
                          well-being, like
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            a.{" "}
                          </span>{" "}
                          Being present for standups/meetings during the morning
                          and letting their managers/supervisors know how their
                          day looks like and what blockers they might face and
                          then collaborating with their manager to come up with
                          solutions to the blockers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            b.
                          </span>{" "}
                          Letting employees come up with timeframes for their
                          tasks and listening to them about the challenges they
                          faced when they are not delivering on the promised
                          timescale and working on resolving those challenges
                          for them.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            c.{" "}
                          </span>
                          Having a more relaxed LOP policy in place in case
                          employees report in sick.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            d.
                          </span>{" "}
                          Allocating a separate budget in addition to employees’
                          salary to sponsor their health expenses, house rent,
                          electricity, WiFi, and food bills during these tough
                          times.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            e.
                          </span>{" "}
                          Arranging face-to-face non-work-related group video
                          calls once a week to let employees know that they are
                          not alone in this and that the company is willing to
                          support them in any way possible.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span class="font-section-small-text-medium">
                            {" "}
                            f.
                          </span>{" "}
                          Not pressuring employees to work during
                          holidays/weekends.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is looking to lay off some of your
                          employees, consider talking to your employees who are
                          earning in 5 or 6 digits (dollars) about taking a pay
                          cut and other possible workarounds instead of
                          lay-offs.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h6 class="font-section-sub-header-small-bold">
                          2. Choose secure and easy-to-use collaboration tools
                        </h6>
                        <img loading="lazy" src={Section5} />
                        <p class="font-section-normal-text mt-4 line-height-2">
                          <a href="https://www.berkeleyside.org/2020/04/08/berkeley-unified-suspends-online-classes-after-naked-zoombomber-enters-session">
                            {" "}
                            Zoombombing.{" "}
                          </a>
                          There, I said it. Happy now? If you’re a company using
                          Zoom for internal communication, given the recent turn
                          of events, I would suggest you look toward
                          alternatives like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Slack or Microsoft Teams.
                          </a>{" "}
                          Because, hey, no one wants a naked man interrupting
                          their team video calls out of the blue ¯\_(ツ)_/¯
                        </p>
                      </div>

                      <div className=" market_wrapper_page">
                        <h6 class="font-section-sub-header-small-bold">
                          3. Build AI-powered digital assistants for every team
                          and automate away
                        </h6>
                        <img loading="lazy" src={Section6} />

                        <p class="font-section-normal-text mt-4 line-height-2">
                          Companies are crazy about
                          <a href="https://medium.com/the-research-nest/reasons-why-digital-transformations-fail-and-how-ai-powered-chatbots-can-help-6e766a9754d9">
                            {" "}
                            digital transformation
                          </a>{" "}
                          now more than ever. Reason? Digital tools and services
                          that’ll probably make{" "}
                          <a href="https://www.servicedeskshow.com/feature/why-change-management-is-important-and-selecting-the-right-automation-tool-for-it-matters/">
                            change management{" "}
                          </a>
                          and business continuity a breeze for them.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Even though digital transformation will always be an
                          ongoing process and not a one-shot thing,
                          digitalization enables companies to streamline their
                          business processes with AI and Automation. And what
                          better way to do that than to start building
                          AI-powered chatbots with automated workflows for
                          internal IT support and HR teams, right? Because, as
                          we saw earlier, these teams definitely need a helping
                          hand.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are a plethora of Platform-As-A-Service
                          offerings online that let you build chatbots. But it
                          all comes down to, do they really serve your business’
                          needs? Because sometimes, businesses get carried away
                          seeing the huge number of features that a chatbot
                          building platform offers instead of carefully
                          examining it for the features that they need at the
                          moment — like a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/features">
                            no-code platform
                          </a>{" "}
                          that enables IT service desk agents to build
                          personalized chatbots themselves, quickly, without any
                          programming knowledge, or a chatbot building platform
                          that has both a conversation builder and an automation
                          builder that interlinks conversations with automated
                          workflows and enables a chatbot to address a user’s IT
                          concern and solve it real-time.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Let’s take a look at what all{" "}
                          <a href="https://workativ.com/conversational-ai-platform/8-best-it-workflow-automation-practices-to-follow-for-chatbots">
                            AI-powered chatbots with automated workflows{" "}
                          </a>
                          can do for you when you build and add them to your
                          company’s Slack or Microsoft Teams workspace.
                        </p>
                        <h5 className="font-section-small-text-medium">
                          a. Central Knowledge Base Access
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Employees can question the chatbot on their team’s
                          Slack or Microsoft Teams channel to know more about
                          the current status of operations and the policies in
                          place to enable work-from-home for them. They can even
                          ask the chatbot to retrieve tech documents for them
                          from the cloud, be it from Google Drive or OneDrive.
                        </p>
                        <h5 className="font-section-small-text-medium">
                          b. Integration with existing business applications
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Instead of letting employees install apps one by one
                          on their home laptops/desktops, internal IT managers
                          and service desk agents can{" "}
                          <a href="https://workativ.com/assistant/integrations">
                            integrate their business applications with the
                            chatbot
                          </a>{" "}
                          to enable a seamless IT support experience for
                          employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Service Desk managers can do this by integrating the
                          application with the chatbot and automating workflows
                          for the application from scratch or just by adding
                          <a href="https://workativ.com/conversational-ai-platform/marketplace">
                            &nbsp;pre-built automations
                          </a>{" "}
                          to their PaaS workspace.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Employees can then use the chatbot to either create an
                          IT support ticket on applications like Freshservice or
                          they can reset their account password themselves with
                          an OTP. As simple as that.
                        </p>
                        <h5 className="font-section-small-text-medium">
                          c. Approvals made easy
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          IT managers can automate workflows for software
                          installation requests from employees in a way that,
                          when an employee has a multi-turn conversation with a
                          chatbot about getting access to software for
                          installing on their laptop/desktop, the IT manager is
                          immediately alerted by the chatbot via email about the
                          request to which the IT manager can give his approval
                          or reject the request on the email itself by clicking
                          on a button on the email.
                        </p>
                        <h5 className="font-section-small-text-medium">
                          d. Proactive self-service for employees
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Why let employees go through the trouble of logging in
                          to a static self-service portal when you can provide
                          feature-rich self-service to them via chatbots on your
                          business’{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack{" "}
                          </a>
                          or Microsoft Teams workspace?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Intelligent chatbots enable two-way communication in
                          natural language with employees, by detecting their
                          issue from their query, consoling/comforting them that
                          it’ll be resolved, then extracting required
                          information from a user’s text input using Natural
                          Language Understanding (NLU), and then resolving their
                          query real-time. Mind-boggling, isn’t it? What’s more,
                          chatbots are always on the look-out for queries from
                          employees 24×7 and learn from their mistakes
                          themselves through Machine Learning. Chatbots also
                          enable seamless hand-offs to service desk agents in
                          case they can’t solve a query. What more can an
                          employee ask for, right?
                        </p>
                        <h5 className="font-section-small-text-medium">
                          e. Reduce overhead for service desk agents
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The last you’d want is for your service desk agents to
                          burn out during times like these. The least you can do
                          for them is to take the load off them by implementing
                          AI-powered contextual chatbots with automated
                          workflows that take care of employees’ L1 and L2 IT
                          support queries, freeing up your service desk agents
                          to focus on tackling and solving complex and
                          challenging L3 issues that’ll let them upskill
                          themselves. Thus reducing employee attrition and
                          improving SLAs, and metrics like Mean Time To Repair
                          (MTTR) and First Call Resolution (FCR).
                        </p>
                      </div>

                      <div className=" market_wrapper_page">
                        <h6 class="font-section-sub-header-small-bold">
                          The road ahead…
                        </h6>
                        <p className="font-section-normal-text line-height-2">
                          Remote working is here to stay, whether you like it or
                          not. Seems like COVID-19 was just a catalyst to force
                          companies worldwide into reconsidering their stance on
                          remote working and overhaul their Business Continuity
                          Planning (BCP) to address the issues at hand, if not
                          for a longer-term.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          Bottom-line? Work more on taking care of your
                          employees by supporting them in every way possible and
                          your employees will take care of your business for
                          you. Clichéd, I know, but that’s the truth.
                        </p>

                        <iframe
                          src="https://www.youtube.com/embed/YnaOfIVivH0?feature=oembed"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          title="video"
                          width="100%"
                          height="460"
                        />

                        <p className="font-section-sub-header-small">
                          #StayHomeAndBuildAIChatbots #Automate #StaySafe
                        </p>
                        <p className="font-section-sub-header-small">
                          If you’re interested in incorporating an AI-powered
                          platform to build digital workers for your workplace
                          IT support that delivers resolutions to employees
                          conversationally with automated workflows on Slack or
                          Microsoft Teams, then look no further.{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Try Workativ today by signing up for a FREE now
                          </a>{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers">
                                Digital Workers to the rescue: Reasons why
                                Digital Transformations fail and how AI-Powered
                                Chatbots with Automated Workflows can help
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                {" "}
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                                Top 8 Human Resource Tasks to Automate for
                                better Employee Experience
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}

            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
